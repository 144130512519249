import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Login from "../../components/login"
import { useQuery, useMutation, useApolloClient } from "@apollo/client"

import {
  BLOG_TOPICS_QUERY,
  NEWSLETTER_BLOCK,
  VALIDATE_NEWSLETTER_SUBSCRIBER,
  NEWSLETTER_SUBSCRIBE_MUTATION,
  BLOG_DETAILS_INFO,
  SEARCH_BLOG_BY_TITLE,
  SEARCH_BLOG_BY_DESCRIPTION,
  BLOG_BY_SELECTEDTOPIC,
  GET_ALL_FAVOURITE_ARTICLES_USER,
  GET_ALL_BOOKMARK_ARTICLES_USER,
  UPDATE_FAVOURITE_ARTICLES_USER,
  UPDATE_BOOKMARK_ARTICLES_USER,
  UPDATE_FAVOURITE_ARTICLES_DATA,
  UPDATE_BOOKMARK_ARTICLES_DATA,
  // USER_QUERY,
  BLOG_TOPIC_URL,
  BLOG_TOPIC_SEO_QUERY,
} from "../../components/graphql"
import moment from "moment"
import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import BounceIndicator from "react-activity/lib/Bounce"
import LinesEllipsis from "react-lines-ellipsis"

import subscription from "../../assets/images/blog/subscription.png"
import trends_placeholder from "../../assets/images/blog/trends_placeholder.jpg"
import shareimg from "../../assets/images/blog/share.png"

import { validateEmailID } from "../../../utils"
import default_profile_img from "../../assets/images/default-profile.png"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share"
import linkedin_icon from "../../assets/images/studydetail/linkedin.png"
import whatsapp_icon from "../../assets/images/studydetail/whatsapp.png"
import twitter_icon from "../../assets/images/studydetail/twitter.png"
import fb_icon from "../../assets/images/studydetail/fb.png"

const BlogIndexPage = ({ location, data, pageContext }) => {
  const client = useApolloClient()

  var locationRef = location.href
  if (locationRef) {
    if (locationRef.slice(-1) === "/") {
      locationRef = locationRef.slice(0, -1)
    }
  }

  console.log("locationRef - topic", locationRef)

  React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
			}
		}
	});
  
  var lastContent = undefined
  var strippedContent = undefined

  if (locationRef) {
    lastContent = locationRef.substring(locationRef.lastIndexOf("/") + 1)

    strippedContent = lastContent.substring(
      0,
      lastContent.indexOf("#") + "#".length - 1
    )

    if (strippedContent === "") {
      strippedContent = lastContent
    }
  }
  const slug = strippedContent
  console.log("strippedContent - topic", slug)

  const blogTopicsData = useQuery(BLOG_TOPICS_QUERY)
  const newsletterBlockContent = useQuery(NEWSLETTER_BLOCK)
  const [subscriberMail, setSubscriberEmail] = useState("")
  const [subscribeError, setSubscribeError] = useState("")
  const [subscribeSucess, setSubscribeSucess] = useState("")
  const [subscriberMail1, setSubscriberEmail1] = useState("")
  const [subscribeError1, setSubscribeError1] = useState("")
  const [subscribeSucess1, setSubscribeSucess1] = useState("")
  const [selectedTopicID, setSelectedTopicID] = useState("")
  const [userFavoriteIDs, setUserFavotiteIDs] = useState([])
  const [userBookmarkIDs, setUserBookmarkIDs] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [userId, setUserID] = useState(undefined)
  const [updateUserFavourite] = useMutation(UPDATE_FAVOURITE_ARTICLES_USER)
  const [updateArticleFavourite] = useMutation(UPDATE_FAVOURITE_ARTICLES_DATA)
  const [updateUserBookmark] = useMutation(UPDATE_BOOKMARK_ARTICLES_USER)
  const [updateArticleBookmark] = useMutation(UPDATE_BOOKMARK_ARTICLES_DATA)
  const [phoneError, setphoneError] = useState('');
  const [successMessage,setSuccessMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");

  const slugContent = useQuery(BLOG_TOPIC_URL, {
    variables: { url: slug },
  })

  React.useEffect(() => {
    console.log("useEffect called")
    setUsername(localStorage.getItem("userName"))
    setUserID(localStorage.getItem("userId"))
  }, [])

  const [showShareOption, setShowShareOption] = useState(false)
  const existingSubscriberCheck = useQuery(VALIDATE_NEWSLETTER_SUBSCRIBER, {
    variables: { subscriber: subscriberMail },
  })

  console.log(selectedTopicID, "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLl")

  if (selectedTopicID === "") {
    console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
    if (slugContent.data) {
      if (slugContent.data.articleTopics.length !== 0) {
        setSelectedTopicID(slugContent.data.articleTopics[0].id)
      }
    }
  }

  if (!slug || slug === "null" || slug === "undefined") {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      window.location.replace("/blog")
    }
  }

  const getBlogContentForSelectedTopic = useQuery(BLOG_BY_SELECTEDTOPIC, {
    variables: { selectedTopicID: selectedTopicID },
  })

  const getSelectedTopicSEO = useQuery(BLOG_TOPIC_SEO_QUERY, {
    variables: { selectedTopicID: selectedTopicID },
  })
  var trendingBlogByTopicCount = 0
  const [showMsg, setShowMsg] = useState(false)
  const [loader, setLoader] = useState(false)
  const topics = []
  const [searchByValues, setSearchByValues] = useState([])

  // const [searchByTopics,setSearchByTopics] = useState()

  // const searchByTopics = useQuery( SEARCH_BLOG_BY_TOPICS,{
  //     variables:{searchValue:searchValue},
  // })
  // console.log("===========================tttttttttttttt",searchByValues)
  // console.log("============================================tttt1111111111111",searchByTopics)
  const trendingArticlesData = useQuery(BLOG_DETAILS_INFO, {
    variables: { type: "TRENDING ARTICLES", limit: 4 },
  })
  const editorPicksData = useQuery(BLOG_DETAILS_INFO, {
    variables: { type: "EDITOR'S PICKS", limit: 7 },
  })
  const articlesByOurAuthorsData = useQuery(BLOG_DETAILS_INFO, {
    variables: { type: "ARTICLES BY OUR AUTHORS", limit: 7 },
  })

  const specialData = useQuery(BLOG_DETAILS_INFO, {
    variables: { type: "SPECIAL ARTICLE", limit: 1 },
  })

  const userFavouriteData = useQuery(GET_ALL_FAVOURITE_ARTICLES_USER, {
    variables: { id: userId ? userId : "" },
    pollInterval: 10000,
  })

  const userBookmarkData = useQuery(GET_ALL_BOOKMARK_ARTICLES_USER, {
    variables: { id: userId ? userId : "" },
    pollInterval: 10000,
  })

  if (userFavouriteData.data) {
    var favArray = []
    userFavouriteData.data.user.favouriteArticles.map((favContent, index) => {
      favArray.push(favContent.id)
    })
    if (JSON.stringify(favArray) !== JSON.stringify(userFavoriteIDs)) {
      setUserFavotiteIDs(favArray)
    }
  }

  if (userBookmarkData.data) {
    var bookArray = []
    userBookmarkData.data.user.bookmarkArticles.map((bookContent, index) => {
      bookArray.push(bookContent.id)
    })
    if (JSON.stringify(bookArray) !== JSON.stringify(userBookmarkIDs)) {
      setUserBookmarkIDs(bookArray)
    }
  }

  const favoriteClick = (blogId, blogContent) => {
    console.log("111111111111111111111111111", blogContent)

    if (localStorage.getItem("userId")) {
      client
        .query({
          query: GET_ALL_FAVOURITE_ARTICLES_USER,
          variables: { id: userId },
        })
        .then((res) => {
          if (res.data.user) {
            let articleList = []
            if (res.data.user.favouriteArticles.length > 0) {
              res.data.user.favouriteArticles.map((record) => {
                articleList.push(record.id)
              })
            }
            articleList.push(blogId)
            updateUserFavourite({
              variables: {
                id: userId,
                articleID: articleList,
              },
            }).then((response) => {
              var count = 0
              if (blogContent.favoriteCount) {
                count = blogContent.favoriteCount
              }
              count = count + 1
              updateArticleFavourite({
                variables: {
                  id: blogId,
                  favoriteCount: count,
                },
              })
                .then((response) => {
                  console.log(response)
                })
                .finally((e) => {
                  // setLiked(true)
                })
            })
          }
        })
    } else {
      console.log("logouttttttttttttttttttttttttt")
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem('lastPage','blog-topics')
      localStorage.setItem('topicSlug',strippedContent?strippedContent:null)
      return false
    }
  }

  const favoriteUnClick = (blogId, blogContent) => {
    console.log("2222222222222222222222222222222", blogContent)
    client
      .query({
        query: GET_ALL_FAVOURITE_ARTICLES_USER,
        variables: { id: userId },
      })
      .then((res) => {
        if (res.data.user) {
          let articleList = []

          if (res.data.user.favouriteArticles.length > 0) {
            res.data.user.favouriteArticles.map((record) => {
              if (blogId !== record.id) {
                articleList.push(record.id)
              }
            })
          }
          updateUserFavourite({
            variables: {
              id: userId,
              articleID: articleList,
            },
          }).then((response) => {
            console.log("reponse", response.data)
            var count = 0
            if (blogContent.favoriteCount) {
              count = blogContent.favoriteCount
            }
            count = count - 1
            updateArticleFavourite({
              variables: {
                id: blogId,
                favoriteCount: count,
              },
            })
              .then((response) => {
                console.log(response)
              })
              .finally((e) => {
                // setLiked(false)
              })
          })
        }
      })
  }

  const bookmarkClick = (blogId, blogContent) => {
    console.log("clickeddddddddddddd", blogContent)
    if (localStorage.getItem("userId")) {
      console.log("XXXXXXXXXXXXXXXXXXXXXXXXX")
      client
        .query({
          query: GET_ALL_BOOKMARK_ARTICLES_USER,
          variables: { id: userId },
        })
        .then((res) => {
          if (res.data.user) {
            let articleList = []
            if (res.data.user.bookmarkArticles.length > 0) {
              res.data.user.bookmarkArticles.map((record) => {
                articleList.push(record.id)
              })
            }
            articleList.push(blogId)
            updateUserBookmark({
              variables: {
                id: userId,
                articleID: articleList,
              },
            }).then((response) => {
              var count = 0
              if (blogContent.wishlistCount) {
                count = blogContent.wishlistCount
              }
              count = count + 1
              updateArticleBookmark({
                variables: {
                  id: blogId,
                  wishlistCount: count,
                },
              })
                .then((response) => {
                  console.log(response)
                })
                .finally((e) => {
                  // setBookMark(true)
                })
            })
          }
        })
    } else {
      console.log("logouttttttttttttttttttttttttt")
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem('lastPage','blog-topics')
      localStorage.setItem('topicSlug',strippedContent?strippedContent:null)
      return false
    }
  }
  const bookmarkUnClick = (blogId, blogContent) => {
    console.log("unclickeddddddddddddddddddd", blogContent)
    client
      .query({
        query: GET_ALL_BOOKMARK_ARTICLES_USER,
        variables: { id: userId },
      })
      .then((res) => {
        if (res.data.user) {
          let articleList = []

          if (res.data.user.bookmarkArticles.length > 0) {
            res.data.user.bookmarkArticles.map((record) => {
              if (blogId !== record.id) {
                articleList.push(record.id)
              }
            })
          }
          updateUserBookmark({
            variables: {
              id: userId,
              articleID: articleList,
            },
          }).then((response) => {
            console.log("reponse", response.data)
            var count = 0
            if (blogContent.wishlistCount) {
              count = blogContent.wishlistCount
            }
            count = count - 1
            updateArticleBookmark({
              variables: {
                id: blogId,
                wishlistCount: count,
              },
            })
              .then((response) => {
                console.log(response)
              })
              .finally((e) => {
                // setBookMark(false)
              })
          })
        }
      })
  }

  // console.log("tttttttttttttttttttt",editorPicksData)
  const [createSubscriber] = useMutation(NEWSLETTER_SUBSCRIBE_MUTATION)

  const handleSubmit = async () => {
    if (validateEmailID(subscriberMail)) {
      try {
        var subscriberLength = existingSubscriberCheck.data
          ? existingSubscriberCheck.data.newsletterSubscribers.length
          : -1
        console.log("subscriberLength", subscriberLength)
        if (subscriberLength === 0) {
          postSubscriber()
        } else {
          setSubscribeSucess("")
          setSubscribeError("EmailID already registered")
        }
      } catch (e) {
        console.log("create user error", e.message)
      }
    } else {
      setSubscribeSucess("")
      setSubscribeError("Enter a valid mail address")
    }
  }

  const handleSubmit1 = async () => {
    if (validateEmailID(subscriberMail)) {
      try {
        var subscriberLength = existingSubscriberCheck.data
          ? existingSubscriberCheck.data.newsletterSubscribers.length
          : -1
        console.log("subscriberLength", subscriberLength)
        if (subscriberLength === 0) {
          postSubscriber1()
        } else {
          setSubscribeSucess1("")
          setSubscribeError1("EmailID already registered")
        }
      } catch (e) {
        console.log("create user error", e.message)
      }
    } else {
      setSubscribeSucess1("")
      setSubscribeError1("Enter a valid mail address")
    }
  }

  const postSubscriber = async () => {
    try {
      await createSubscriber({
        variables: {
          newsletterInput: {
            data: {
              Subscriber: subscriberMail,
            },
          },
        },
      }).then((createSubscriberResponse) => {
        console.log("reponse", createSubscriberResponse.data)
        if (createSubscriberResponse.data.length !== 0) {
          const result = addToMailchimp(subscriberMail, {
            "group[223596][1]": true,
            "group[223596][2]": true,
            "group[223596][4]": true,
          })
          console.log("result", result)
          setSubscriberEmail("")
          setSubscribeError("")
          setSubscribeSucess("Subscribed successfully...!")
        }
      })
    } catch (e) {
      setSubscribeSucess("")
      console.log("post subcriber error", e.message)
    }
  }

  const postSubscriber1 = async () => {
    try {
      await createSubscriber({
        variables: {
          newsletterInput: {
            data: {
              Subscriber: subscriberMail,
            },
          },
        },
      }).then((createSubscriberResponse) => {
        console.log("reponse", createSubscriberResponse.data)
        if (createSubscriberResponse.data.length !== 0) {
          setSubscriberEmail1("")
          setSubscribeError1("")
          setSubscribeSucess1("Subscribed successfully...!")
          const result = addToMailchimp(subscriberMail)
          console.log("result", result)
        }
      })
    } catch (e) {
      setSubscribeSucess1("")
      console.log("post subcriber error", e.message)
    }
  }

  const searchFun = (e) => {
    setSearchValue(e.target.value)
    if (e.target.value.length > 0 && e.target.value.length <= 2) {
      setShowMsg(true)
    } else {
      setShowMsg(false)
    }
    if (e.target.value.length >= 3) {
      setLoader(true)
      setTimeout(() => {
        client
          .query({
            query: SEARCH_BLOG_BY_TITLE,
            variables: { searchValue: searchValue },
          })
          .then((res) => {
            if (res.data) {
              let topics = res.data.articles
              client
                .query({
                  query: SEARCH_BLOG_BY_DESCRIPTION,
                  variables: { searchValue: searchValue },
                })
                .then((res) => {
                  let description = []
                  if (res.data) {
                    description = res.data.articles
                  }
                  const obj = [
                    ...new Map(
                      topics
                        .concat(description)
                        .map((item) => [JSON.stringify(item), item])
                    ).values(),
                  ]
                  setLoader(false)
                  setSearchByValues(obj)
                })
            }
            // if(res.data){
            //     console.log("oooooooooooooooo",res.data.articles)
            // }
            // let ids=[{id:"1",value:"aaaa"},{id:"2",value:"aaaa"},{id:"3",value:"aaaa"},{id:"4",value:"aaaa"}]
            // let ids1=[{id:"3",value:"aaaa"},{id:"4",value:"aaaa"},{id:"5",value:"aaaa"},{id:"6",value:"aaaa"}]
            // console.log("FFFFFFFFFFFFFFFFFFFFFFFF",ids.concat(ids1))
            // const obj = [...new Map((ids.concat(ids1)).map(item => [JSON.stringify(item), item])).values()];
            // console.log("HHHHHHHHHHYYYYYYYYYYYY",obj)
          })
      }, 3000)
    } else {
      setLoader(false)
    }
  }

  var trendingSelectedTopicContent = []
  var allSelectedTopicContent = []
  if (getBlogContentForSelectedTopic) {
    if (getBlogContentForSelectedTopic.data) {
      if (getBlogContentForSelectedTopic.data.articles.length !== 0) {
        var mappedTrendContent = []
        var mappedAllContent = []
        getBlogContentForSelectedTopic.data.articles.map(
          (articleContent, index) => {
            var isTrendingCheck = articleContent.articleTypes.some((item) => {
              if (item.type === "TRENDING ARTICLES") {
                return true
              }
            })
            if (mappedTrendContent.length !== 4) {
              if (isTrendingCheck === true) {
                mappedTrendContent.push(articleContent)
              } else {
                mappedAllContent.push(articleContent)
              }
            } else {
              mappedAllContent.push(articleContent)
            }
          }
        )
        trendingSelectedTopicContent = mappedTrendContent
        allSelectedTopicContent = mappedAllContent
      }
    }
  }

  var handleFavoriteCheck = (val) => {
    return userFavoriteIDs.some((item) => val === item)
  }
  var handleBookmarkCheck = (val) => {
    return userBookmarkIDs.some((item) => val === item)
  }

  const { strapiArticleTopic, allStrapiHomePage } = data
  const seoTitle = strapiArticleTopic !== null ? strapiArticleTopic.title : ""
  const seoMetaDescription =
    strapiArticleTopic !== null ? strapiArticleTopic.metaDescription : ""
  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""
  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout setTitle="Blog" tag="blog">
        <Login
          open={open}
          setOpen={setOpen}
          login={login}
          setLogin={setLogin}
          setUsername={setUsername}
          setUserID={setUserID}
          forgotPassword={forgotPassword}
          setForgotPassword={setForgotPassword}
          errorMessage ={errorMessage}
          setErrorMessage ={setErrorMessage}
          successMessage ={successMessage}
          setSuccessMessage ={setSuccessMessage}
          phoneError ={phoneError}
          setphoneError ={setphoneError}
        />
        <div id="blog-main-content" className="main-content">
          <div className="container">
            <div className="blog-page">
              <div className="explore">
                <div className="title">Explore By Topics</div>
                <div className="subtitle">
                  Ready To Brush Up On Something New? We've Got More To Read
                  Right This Way.
                </div>
              </div>
              {/* <div className="search-box">
              <input
                type="search"
                id="gsearch"
                name="gsearch"
                value={searchValue}
                onChange={searchFun}
                placeholder="Search By Keywords"
              />
              {showMsg ? <span className="search-message">Please enter at least 3 characters</span>:""}
            </div> */}
              <div className="blog-list row">
                {blogTopicsData.data
                  ? blogTopicsData.data.articleTopics.map((content, index) => (
                      <Link
                        to={`/blog/topic/${content.url}/`}
                        // state={{ id: content.id }}
                        onClick={() => {
                          setSelectedTopicID(content.id)
                        }}
                        style={{
                          textDecoration: "none",
                        }}
                        key={content.id}
                      >
                        <div className="list col-sm-3">
                          <div
                            className="list-box"
                            style={{
                              color:
                                selectedTopicID === content.id
                                  ? "rgb(0, 85, 164)"
                                  : "#000000",
                            }}
                          >
                            <img
                              src={
                                content.thumbnail
                                  ? process.env.BACKEND_URL +
                                    content.thumbnail.url
                                  : ""
                              }
                              alt={content.altThumbnailName}
                              className="image"
                            />
                            {content.title}
                          </div>
                        </div>
                      </Link>
                    ))
                  : null}
              </div>
              {searchValue.length < 3 ? (
                <>
                  {selectedTopicID !== "" ? (
                    <div className="trending">
                      {getBlogContentForSelectedTopic.data ? (
                        <>
                          {
                            <>
                              {trendingSelectedTopicContent.length !== 0 ? (
                                <div className="trends row-top row">
                                  <div className="title">TRENDING ARTICLES</div>
                                  {trendingSelectedTopicContent.map(
                                    (content, index) => (
                                      <div
                                        className="col-md-6 col-lg-3"
                                        key={index}
                                      >
                                        <div className="deal-list right">
                                          <div className="image">
                                            {content.image.length >= 1 ? (
                                              <img
                                                src={
                                                  content.image[0].url
                                                    ? process.env.BACKEND_URL +
                                                      content.image[0].url
                                                    : trends_placeholder
                                                }
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src={trends_placeholder}
                                                alt=""
                                              />
                                            )}
                                            <div className="icons">
                                              <div class="overlay">
                                                <div class="heart">
                                                  {!handleFavoriteCheck(
                                                    content.id
                                                  ) ? (
                                                    <i
                                                      class="far fa-heart"
                                                      onClick={() => {
                                                        favoriteClick(
                                                          content.id,
                                                          content
                                                        )
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      class="fas fa-heart"
                                                      style={{
                                                        color: "#ff0000",
                                                      }}
                                                      onClick={() => {
                                                        favoriteUnClick(
                                                          content.id,
                                                          content
                                                        )
                                                      }}
                                                    ></i>
                                                  )}
                                                  {content.favoriteCount}
                                                </div>
                                                <div class="tag">
                                                  {!handleBookmarkCheck(
                                                    content.id
                                                  ) ? (
                                                    <i
                                                      class="far fa-bookmark"
                                                      onClick={() => {
                                                        bookmarkClick(
                                                          content.id,
                                                          content
                                                        )
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      class="fas fa-bookmark"
                                                      onClick={() => {
                                                        bookmarkUnClick(
                                                          content.id,
                                                          content
                                                        )
                                                      }}
                                                    ></i>
                                                  )}
                                                  {content.wishlistCount}
                                                </div>
                                                <div class="share">
                                                  <img
                                                    src={shareimg}
                                                    alt=""
                                                    className="share-img"
                                                    onClick={() => {
                                                      !showShareOption
                                                        ? setShowShareOption(
                                                            true
                                                          )
                                                        : setShowShareOption(
                                                            false
                                                          )
                                                    }}
                                                  />
                                                  {showShareOption ? (
                                                    <div class="social">
                                                      <ul>
                                                        <li>
                                                          <FacebookShareButton
                                                            quote={
                                                              seoMetaDescription || seoTitle
                                                            }
                                                            url={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            hashtag="#dimensionsFrance"
                                                          >
                                                            <img
                                                              src={fb_icon}
                                                              alt="facebook"
                                                              style={{
                                                                height: "20px",
                                                                width: "10px",
                                                              }}
                                                            />
                                                          </FacebookShareButton>
                                                        </li>
                                                        <li>
                                                          <LinkedinShareButton
                                                            title={
                                                              "Dimensions France"
                                                            }
                                                            summary={
                                                              seoMetaDescription || seoTitle
                                                            }
                                                            url={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            source={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            hashtag="#dimensionsFrance"
                                                          >
                                                            <img
                                                              src={
                                                                linkedin_icon
                                                              }
                                                              alt="linkedin"
                                                            />
                                                          </LinkedinShareButton>
                                                        </li>
                                                        <li>
                                                          <WhatsappShareButton
                                                            title={
                                                              seoMetaDescription || seoTitle
                                                            }
                                                            url={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            hashtag="#dimensionsFrance"
                                                          >
                                                            <img
                                                              src={
                                                                whatsapp_icon
                                                              }
                                                              alt="whatsapp"
                                                            />
                                                          </WhatsappShareButton>
                                                        </li>
                                                        <li>
                                                          <TwitterShareButton
                                                            title={
                                                              seoMetaDescription || seoTitle
                                                            }
                                                            url={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            hashtag="#dimensionsFrance"
                                                          >
                                                            <img
                                                              src={twitter_icon}
                                                              alt="twitter"
                                                            />
                                                          </TwitterShareButton>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="details">
                                            <h6>
                                              <img
                                                style={{ width: 14 }}
                                                src={
                                                  content.topic.thumbnail
                                                    ? process.env.BACKEND_URL +
                                                      content.topic.thumbnail
                                                        .url
                                                    : ""
                                                }
                                                alt=""
                                              />
                                              <span>{content.topic.title}</span>
                                            </h6>
                                            <div className="dtitle editicon">
                                              <Link
                                                // state={{ id: content.id }}
                                                to={`/blog/${content.url}/`}
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                              >
                                                <LinesEllipsis
                                                  text={content.title}
                                                  maxLine="3"
                                                  ellipsis="..."
                                                  trimRight
                                                  basedOn="letters"
                                                />
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="s-icon">
                                            <div className="image">
                                              <img
                                                src={
                                                  content.author
                                                  ?content.author.profileImage
                                                    ? process.env.BACKEND_URL +
                                                      content.author
                                                        .profileImage.url
                                                        : default_profile_img
                                                    : default_profile_img
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="right">
                                              <div className="name">
                                                {content.author?content.author.firstName:""}{" "}
                                                {content.author?content.author.lastName:""}
                                              </div>
                                              <div className="date">
                                                {content.blogDate ? (
                                                  <>
                                                    {moment(
                                                      content.blogDate
                                                    ).format("MMMM DD")}
                                                  </>
                                                ) : (
                                                  ""
                                                )}{" "}
                                                |{" "}
                                                {content.ReadingTimeInMinutes
                                                  ? content.ReadingTimeInMinutes
                                                  : 0}{" "}
                                                Min Read
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null}
                              {allSelectedTopicContent.length !== 0 ? (
                                <div className="trends row-top row">
                                  <div className="title">ALL ARTICLES</div>
                                  {allSelectedTopicContent.map(
                                    (content, index) => (
                                      <div
                                        className="col-md-6 col-lg-3 mb-30"
                                        key={index}
                                      >
                                        <div className="deal-list right">
                                          <div className="image">
                                            {content.image.length >= 1 ? (
                                              <img
                                                src={
                                                  content.image[0].url
                                                    ? process.env.BACKEND_URL +
                                                      content.image[0].url
                                                    : trends_placeholder
                                                }
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src={trends_placeholder}
                                                alt=""
                                              />
                                            )}
                                            <div className="icons">
                                              <div class="overlay">
                                                <div class="heart">
                                                  {!handleFavoriteCheck(
                                                    content.id
                                                  ) ? (
                                                    <i
                                                      class="far fa-heart"
                                                      onClick={() => {
                                                        favoriteClick(
                                                          content.id,
                                                          content
                                                        )
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      class="fas fa-heart"
                                                      style={{
                                                        color: "#ff0000",
                                                      }}
                                                      onClick={() => {
                                                        favoriteUnClick(
                                                          content.id,
                                                          content
                                                        )
                                                      }}
                                                    ></i>
                                                  )}
                                                  {content.favoriteCount}
                                                </div>
                                                <div class="tag">
                                                  {!handleBookmarkCheck(
                                                    content.id
                                                  ) ? (
                                                    <i
                                                      class="far fa-bookmark"
                                                      onClick={() => {
                                                        bookmarkClick(
                                                          content.id,
                                                          content
                                                        )
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      class="fas fa-bookmark"
                                                      onClick={() => {
                                                        bookmarkUnClick(
                                                          content.id,
                                                          content
                                                        )
                                                      }}
                                                    ></i>
                                                  )}
                                                  {content.wishlistCount}
                                                </div>
                                                <div class="share">
                                                  <img
                                                    src={shareimg}
                                                    alt=""
                                                    className="share-img"
                                                    onClick={() => {
                                                      !showShareOption
                                                        ? setShowShareOption(
                                                            true
                                                          )
                                                        : setShowShareOption(
                                                            false
                                                          )
                                                    }}
                                                  />
                                                  {showShareOption ? (
                                                    <div class="social">
                                                      <ul>
                                                        <li>
                                                          <FacebookShareButton
                                                            quote={
                                                              seoMetaDescription || seoTitle
                                                            }
                                                            url={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            hashtag="#dimensionsFrance"
                                                          >
                                                            <img
                                                              src={fb_icon}
                                                              alt="facebook"
                                                              style={{
                                                                height: "20px",
                                                                width: "10px",
                                                              }}
                                                            />
                                                          </FacebookShareButton>
                                                        </li>
                                                        <li>
                                                          <LinkedinShareButton
                                                            title={
                                                              "Dimensions France"
                                                            }
                                                            summary={
                                                              seoMetaDescription || seoTitle
                                                            }
                                                            url={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            source={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            hashtag="#dimensionsFrance"
                                                          >
                                                            <img
                                                              src={
                                                                linkedin_icon
                                                              }
                                                              alt="linkedin"
                                                            />
                                                          </LinkedinShareButton>
                                                        </li>
                                                        <li>
                                                          <WhatsappShareButton
                                                            title={
                                                              seoMetaDescription || seoTitle
                                                            }
                                                            url={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            hashtag="#dimensionsFrance"
                                                          >
                                                            <img
                                                              src={
                                                                whatsapp_icon
                                                              }
                                                              alt="whatsapp"
                                                            />
                                                          </WhatsappShareButton>
                                                        </li>
                                                        <li>
                                                          <TwitterShareButton
                                                            title={
                                                              seoMetaDescription || seoTitle
                                                            }
                                                            url={
                                                              process.env
                                                                .GATSBY_URL +
                                                              `/blog/${content.url}/`
                                                            }
                                                            hashtag="#dimensionsFrance"
                                                          >
                                                            <img
                                                              src={twitter_icon}
                                                              alt="twitter"
                                                            />
                                                          </TwitterShareButton>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="details">
                                            <h6>
                                              <img
                                                src={
                                                  content.topic.thumbnail
                                                    ? process.env.BACKEND_URL +
                                                      content.topic.thumbnail
                                                        .url
                                                    : ""
                                                }
                                                alt=""
                                              />
                                              <span>{content.topic.title}</span>
                                            </h6>
                                            <div className="dtitle editicon">
                                              <Link
                                                // state={{ id: content.id }}
                                                to={`/blog/${content.url}/`}
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                              >
                                                <LinesEllipsis
                                                  text={content.title}
                                                  maxLine="3"
                                                  ellipsis="..."
                                                  trimRight
                                                  basedOn="letters"
                                                />
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="s-icon">
                                            <div className="image">
                                              <img
                                                src={
                                                  content.author?
                                                  content.author.profileImage
                                                    ? process.env.BACKEND_URL +
                                                      content.author
                                                        .profileImage.url
                                                    : default_profile_img
                                                    : default_profile_img
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="right">
                                              <div className="name">
                                                {content.author?content.author.firstName:""}{" "}
                                                {content.author?content.author.lastName:""}
                                              </div>
                                              <div className="date">
                                                {content.blogDate ? (
                                                  <>
                                                    {moment(
                                                      content.blogDate
                                                    ).format("MMMM DD")}
                                                  </>
                                                ) : (
                                                  ""
                                                )}{" "}
                                                |{" "}
                                                {content.ReadingTimeInMinutes
                                                  ? content.ReadingTimeInMinutes
                                                  : 0}{" "}
                                                Min Read
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null}
                            </>
                          }
                          {console.log(
                            "condition check",
                            trendingSelectedTopicContent.length !== 0 &&
                              allSelectedTopicContent.length !== 0
                          )}
                          {trendingSelectedTopicContent.length !==
                          0 ? null : allSelectedTopicContent.length !==
                            0 ? null : (
                            <div className="trends row-top row">
                              <div className="no-result">
                                <h1>No results found.</h1>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className={"centered-loader"}>
                            <BounceIndicator size={25} color={"#0055a4"} />
                            <span style={{ margin: "10px" }}>Loading...</span>
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {loader ? (
                    <>
                      <div className={"centered-loader"}>
                        <BounceIndicator size={25} color={"#0055a4"} />
                        <span style={{ margin: "10px" }}>Loading...</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="trending">
                        {searchByValues.length > 0 ? (
                          <div className="trends row-top row">
                            <div className="title">
                              Search results for: {searchValue}
                            </div>
                            {searchByValues.map((content, index) => (
                              <div
                                className="col-md-6 col-lg-3 mb-30"
                                key={index}
                              >
                                <div className="deal-list right">
                                  <div className="image">
                                    {content.image.length >= 1 ? (
                                      <img
                                        src={
                                          content.image[0].url
                                            ? process.env.BACKEND_URL +
                                              content.image[0].url
                                            : trends_placeholder
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img src={trends_placeholder} alt="" />
                                    )}
                                    <div className="icons">
                                      <div class="overlay">
                                        <div class="heart">
                                          {!handleFavoriteCheck(content.id) ? (
                                            <i
                                              class="far fa-heart"
                                              onClick={() => {
                                                favoriteClick(
                                                  content.id,
                                                  content
                                                )
                                              }}
                                            ></i>
                                          ) : (
                                            <i
                                              class="fas fa-heart"
                                              style={{
                                                color: "#ff0000",
                                              }}
                                              onClick={() => {
                                                favoriteUnClick(
                                                  content.id,
                                                  content
                                                )
                                              }}
                                            ></i>
                                          )}
                                          {content.favoriteCount}
                                        </div>
                                        <div class="tag">
                                          {!handleBookmarkCheck(content.id) ? (
                                            <i
                                              class="far fa-bookmark"
                                              onClick={() => {
                                                bookmarkClick(
                                                  content.id,
                                                  content
                                                )
                                              }}
                                            ></i>
                                          ) : (
                                            <i
                                              class="fas fa-bookmark"
                                              onClick={() => {
                                                bookmarkUnClick(
                                                  content.id,
                                                  content
                                                )
                                              }}
                                            ></i>
                                          )}
                                          {content.wishlistCount}
                                        </div>
                                        <div class="share">
                                          <img
                                            src={shareimg}
                                            alt=""
                                            className="share-img"
                                            onClick={() => {
                                              !showShareOption
                                                ? setShowShareOption(true)
                                                : setShowShareOption(false)
                                            }}
                                          />
                                          {showShareOption ? (
                                            <div class="social">
                                              <ul>
                                                <li>
                                                  <FacebookShareButton
                                                    quote={
                                                      seoMetaDescription || seoTitle
                                                    }
                                                    url={
                                                      process.env.GATSBY_URL +
                                                      `/blog/${content.url}/`
                                                    }
                                                    hashtag="#dimensionsFrance"
                                                  >
                                                    <img
                                                      src={fb_icon}
                                                      alt="facebook"
                                                      style={{
                                                        height: "20px",
                                                        width: "10px",
                                                      }}
                                                    />
                                                  </FacebookShareButton>
                                                </li>
                                                <li>
                                                  <LinkedinShareButton
                                                    title={"Dimensions France"}
                                                    summary={
                                                      seoMetaDescription || seoTitle
                                                    }
                                                    url={
                                                      process.env.GATSBY_URL +
                                                      `/blog/${content.url}/`
                                                    }
                                                    source={
                                                      process.env.GATSBY_URL +
                                                      `/blog/${content.url}/`
                                                    }
                                                    hashtag="#dimensionsFrance"
                                                  >
                                                    <img
                                                      src={linkedin_icon}
                                                      alt="linkedin"
                                                    />
                                                  </LinkedinShareButton>
                                                </li>
                                                <li>
                                                  <WhatsappShareButton
                                                    title={
                                                      seoMetaDescription || seoTitle
                                                    }
                                                    url={
                                                      process.env.GATSBY_URL +
                                                      `/blog/${content.url}/`
                                                    }
                                                    hashtag="#dimensionsFrance"
                                                  >
                                                    <img
                                                      src={whatsapp_icon}
                                                      alt="whatsapp"
                                                    />
                                                  </WhatsappShareButton>
                                                </li>
                                                <li>
                                                  <TwitterShareButton
                                                    title={
                                                      seoMetaDescription || seoTitle
                                                    }
                                                    url={
                                                      process.env.GATSBY_URL +
                                                      `/blog/${content.url}/`
                                                    }
                                                    hashtag="#dimensionsFrance"
                                                  >
                                                    <img
                                                      src={twitter_icon}
                                                      alt="twitter"
                                                    />
                                                  </TwitterShareButton>
                                                </li>
                                              </ul>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="details">
                                    <h6>
                                      <img
                                        src={
                                          content.topic.thumbnail
                                            ? process.env.BACKEND_URL +
                                              content.topic.thumbnail.url
                                            : ""
                                        }
                                        alt=""
                                      />
                                      <span>{content.topic.title}</span>
                                    </h6>
                                    <div className="dtitle editicon">
                                      <Link
                                        // state={{ id: content.id }}
                                        to={`/blog/${content.url}/`}
                                        style={{
                                          textDecoration: "none",
                                        }}
                                      >
                                        <LinesEllipsis
                                          text={content.title}
                                          maxLine="3"
                                          ellipsis="..."
                                          trimRight
                                          basedOn="letters"
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="s-icon">
                                    <div className="image">
                                      <img
                                        src={
                                          content.author?
                                          content.author.profileImage
                                            ? process.env.BACKEND_URL +
                                              content.author.profileImage.url
                                            : default_profile_img
                                            : default_profile_img
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="right">
                                      <div className="name">
                                        {content.author?content.author.firstName:""}{" "}
                                        {content.author?content.author.lastName:""}
                                      </div>
                                      <div className="date">
                                        {content.blogDate ? (
                                          <>
                                            {moment(content.blogDate).format(
                                              "MMMM DD"
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}{" "}
                                        |{" "}
                                        {content.ReadingTimeInMinutes
                                          ? content.ReadingTimeInMinutes
                                          : 0}{" "}
                                        Min Read
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <>
                            <div className="trends row-top row">
                              <div className="no-result">
                                <h1>No results found.</h1>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}

              <div className="trending">
                {newsletterBlockContent.data ? (
                  <div className="subscribe mb-100">
                    <div className="title">
                      {newsletterBlockContent.data.newsletterBlock.title}
                    </div>
                    <div className="s-news">
                      <div className="form-section">
                        <div className="subtitle">
                          {
                            newsletterBlockContent.data.newsletterBlock
                              .shortDescription
                          }
                        </div>

                        <div className="newsletterform">
                          <input
                            type="email"
                            id="n_email"
                            name="email"
                            value={subscriberMail}
                            placeholder="Email Address"
                            onChange={(e) => {
                              setSubscriberEmail(e.target.value)
                            }}
                          />
                          <div className="talk newsletter">
                            <button
                              onClick={() => handleSubmit()}
                              className="newsletterbutton"
                            >
                              {
                                newsletterBlockContent.data.newsletterBlock
                                  .buttonName
                              }
                            </button>
                          </div>
                        </div>
                        <div className="success-message">{subscribeSucess}</div>
                        <div className="error-message">{subscribeError}</div>
                        {subscribeError === "" ? <br /> : null}
                      </div>
                      <div className="subs-image">
                        <img src={subscription} alt="" />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <!-- /container ---> */}
        </div>
      </Layout>
    </>
  )
}
export default BlogIndexPage

export const query = graphql`
  query blogTopicWithID($pageID: String) {
    strapiArticleTopic(id: { eq: $pageID }) {
      title
      metaDescription
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
